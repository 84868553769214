'use strict';
// 以下のシートで一括マッピングして追加しました
// https://docs.google.com/spreadsheets/d/14v5IohgyEZxiDG_wB7ZqGqHrNx79zYmok1MdXTXSvUQ/edit#gid=0
// ソースはhttps://ja.wikipedia.org/wiki/ISO_3166-1
module.exports = {
  JP: '日本',
  US: 'アメリカ合衆国',
  IS: 'アイスランド',
  IE: 'アイルランド',
  AZ: 'アゼルバイジャン',
  AF: 'アフガニスタン',
  VI: 'アメリカ領ヴァージン諸島',
  AS: 'アメリカ領サモア',
  AE: 'アラブ首長国連邦',
  DZ: 'アルジェリア',
  AR: 'アルゼンチン',
  AW: 'アルバ',
  AL: 'アルバニア',
  AM: 'アルメニア',
  AI: 'アンギラ',
  AO: 'アンゴラ',
  AG: 'アンティグア・バーブーダ',
  AD: 'アンドラ',
  YE: 'イエメン',
  GB: 'イギリス',
  VG: 'イギリス領ヴァージン諸島',
  IL: 'イスラエル',
  IT: 'イタリア',
  IQ: 'イラク',
  IR: 'イラン・イスラム共和国',
  IN: 'インド',
  ID: 'インドネシア',
  UG: 'ウガンダ',
  UA: 'ウクライナ',
  UZ: 'ウズベキスタン',
  UY: 'ウルグアイ',
  EC: 'エクアドル',
  EG: 'エジプト',
  EE: 'エストニア',
  SZ: 'エスワティニ',
  ET: 'エチオピア',
  ER: 'エリトリア',
  SV: 'エルサルバドル',
  AU: 'オーストラリア',
  AT: 'オーストリア',
  OM: 'オマーン',
  NL: 'オランダ',
  GH: 'ガーナ',
  CV: 'カーボベルデ',
  GG: 'ガーンジー',
  GY: 'ガイアナ',
  KZ: 'カザフスタン',
  QA: 'カタール',
  CA: 'カナダ',
  GA: 'ガボン',
  CM: 'カメルーン',
  GM: 'ガンビア',
  KH: 'カンボジア',
  GN: 'ギニア',
  GW: 'ギニアビサウ',
  CY: 'キプロス',
  CU: 'キューバ',
  GR: 'ギリシャ',
  KI: 'キリバス',
  KG: 'キルギス',
  GT: 'グアテマラ',
  GP: 'グアドループ',
  GU: 'グアム',
  KW: 'クウェート',
  GL: 'グリーンランド',
  GD: 'グレナダ',
  HR: 'クロアチア',
  KY: 'ケイマン諸島',
  KE: 'ケニア',
  CI: 'コートジボワール',
  CR: 'コスタリカ',
  XY: 'コソボ',
  KM: 'コモロ',
  CO: 'コロンビア',
  CG: 'コンゴ共和国',
  CD: 'コンゴ民主共和国',
  SP: 'サイパン',
  SA: 'サウジアラビア',
  WS: 'サモア',
  ST: 'サントメ・プリンシペ',
  BL: 'サン・バルテルミー',
  ZM: 'ザンビア',
  PM: 'サンピエール島・ミクロン島',
  SM: 'サンマリノ',
  SL: 'シエラレオネ',
  DJ: 'ジブチ',
  GI: 'ジブラルタル',
  JE: 'ジャージー',
  JM: 'ジャマイカ',
  GE: 'ジョージア',
  SY: 'シリア・アラブ共和国',
  SG: 'シンガポール',
  ZW: 'ジンバブエ',
  CH: 'スイス',
  SE: 'スウェーデン',
  SD: 'スーダン',
  ES: 'スペイン',
  SR: 'スリナム',
  LK: 'スリランカ',
  SK: 'スロバキア',
  SI: 'スロベニア',
  SC: 'セーシェル',
  SN: 'セネガル',
  RS: 'セルビア',
  KN: 'セントクリストファー・ネイビス',
  VC: 'セントビンセントおよびグレナディーン諸島',
  SH: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
  LC: 'セントルシア',
  SO: 'ソマリア',
  SB: 'ソロモン諸島',
  TC: 'タークス・カイコス諸島',
  TH: 'タイ',
  TJ: 'タジキスタン',
  TZ: 'タンザニア',
  CZ: 'チェコ',
  TD: 'チャド',
  TN: 'チュニジア',
  CL: 'チリ',
  TV: 'ツバル',
  DK: 'デンマーク',
  DE: 'ドイツ',
  TG: 'トーゴ',
  DO: 'ドミニカ共和国',
  DM: 'ドミニカ国',
  TT: 'トリニダード・トバゴ',
  TM: 'トルクメニスタン',
  TR: 'トルコ',
  TO: 'トンガ',
  NG: 'ナイジェリア',
  NR: 'ナウル',
  NA: 'ナミビア',
  NI: 'ニカラグア',
  NE: 'ニジェール',
  NC: 'ニューカレドニア',
  NZ: 'ニュージーランド',
  NP: 'ネパール',
  NO: 'ノルウェー',
  BH: 'バーレーン',
  HT: 'ハイチ',
  PK: 'パキスタン',
  VA: 'バチカン市国',
  PA: 'パナマ',
  VU: 'バヌアツ',
  BS: 'バハマ',
  PG: 'パプアニューギニア',
  BM: 'バミューダ',
  PW: 'パラオ',
  PY: 'パラグアイ',
  BB: 'バルバドス',
  HU: 'ハンガリー',
  BD: 'バングラデシュ',
  PN: 'ピトケアン',
  FJ: 'フィジー',
  PH: 'フィリピン',
  FI: 'フィンランド',
  BT: 'ブータン',
  PR: 'プエルトリコ',
  FO: 'フェロー諸島',
  FK: 'フォークランド（マルビナス）諸島',
  BR: 'ブラジル',
  FR: 'フランス',
  GF: 'フランス領ギアナ',
  PF: 'フランス領ポリネシア',
  BG: 'ブルガリア',
  BF: 'ブルキナファソ',
  BN: 'ブルネイ・ダルサラーム',
  BI: 'ブルンジ',
  VN: 'ベトナム',
  BJ: 'ベナン',
  VE: 'ベネズエラ・ボリバル共和国',
  BY: 'ベラルーシ',
  BZ: 'ベリーズ',
  PE: 'ペルー',
  BE: 'ベルギー',
  PL: 'ポーランド',
  BA: 'ボスニア・ヘルツェゴビナ',
  BW: 'ボツワナ',
  BO: 'ボリビア多民族国',
  PT: 'ポルトガル',
  HN: 'ホンジュラス',
  MH: 'マーシャル諸島',
  MO: 'マカオ',
  MG: 'マダガスカル',
  MW: 'マラウイ',
  ML: 'マリ',
  MT: 'マルタ',
  MQ: 'マルティニーク',
  MY: 'マレーシア',
  IM: 'マン島',
  FM: 'ミクロネシア連邦',
  MI: 'ミッドウェイ',
  MM: 'ミャンマー',
  MX: 'メキシコ',
  MU: 'モーリシャス',
  MR: 'モーリタニア',
  MZ: 'モザンビーク',
  MC: 'モナコ',
  MV: 'モルディブ',
  MD: 'モルドバ共和国',
  MA: 'モロッコ',
  MN: 'モンゴル',
  ME: 'モンテネグロ',
  MS: 'モントセラト',
  JO: 'ヨルダン',
  LA: 'ラオス人民民主共和国',
  LV: 'ラトビア',
  LT: 'リトアニア',
  LY: 'リビア',
  LI: 'リヒテンシュタイン',
  LR: 'リベリア',
  RO: 'ルーマニア',
  LU: 'ルクセンブルク',
  RW: 'ルワンダ',
  LS: 'レソト',
  LB: 'レバノン',
  RE: 'レユニオン',
  RU: 'ロシア連邦',
  HK: '香港',
  UM: '合衆国領有小離島',
  GQ: '赤道ギニア',
  TW: '台湾',
  KR: '大韓民国',
  CF: '中央アフリカ共和国',
  CN: '中華人民共和国',
  TL: '東ティモール',
  ZA: '南アフリカ',
  SS: '南スーダン',
  MK: '北マケドニア',
  MP: '北マリアナ諸島',
  NT: '北方諸島'
};
