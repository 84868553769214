'use strict';

const STATE = {
  PREPARATION: 'PREPARATION',
  APPROVED: 'APPROVED',
  SHIPPED: 'SHIPPED',
  CANCELED: 'CANCELED',
  RETURNED: 'RETURNED'
};

const STATE_PATTERN = [];
for (const state in STATE) {
  STATE_PATTERN.push(state);
}

const STATE_LABEL = {
  PREPARATION: '未発送',
  APPROVED: '出荷作業中',
  SHIPPED: '発送済み',
  CANCELED: '取り消し',
  RETURNED: '返送'
};

const SHIPPING_METHOD_LABEL = {
  JPP_EMS: 'EMS',
  JPP_AIR_PARCEL: '国際小包_航空便',
  JPP_SAL_PARCEL: '国際小包_SAL便',
  JPP_SURFACE_PARCEL: '国際小包_船便',
  JPP_SAL_SPR: '小形包装物_SAL便_書留',
  JPP_AIR_SPR: '小形包装物_航空便_書留',
  JPP_E_PACKET: '国際eパケット', // 国際eパケットは廃止されたが過去の表示のため消せない
  JPP_AIR_PRINTED_MATTER_REGISTERED: '国際郵便_印刷物_航空便_書留',
  YAMATO: 'ヤマト運輸',
  YAMATO_NEKOPOS: 'ネコポス',
  SAGAWA: '佐川急便',
  CHARTER: 'チャーター便',
  SLS: 'SLS(Shopee Logistics Service)',
  FEDEX_PRIORITY: 'FedEx_プライオリティ',
  FEDEX_ECONOMY: 'FedEx_エコノミー',
  FEDEX_CONNECT_PLUS: 'FedEx_ICP',
  DHL_EXPRESS: 'DHL_エクスプレス',
  ECMS: 'ECMS'
};

const SHIPPING_METHOD_WITH_DUTY = {
  FEDEX_PRIORITY: 'FEDEX_PRIORITY',
  FEDEX_ECONOMY: 'FEDEX_ECONOMY',
  FEDEX_CONNECT_PLUS: 'FEDEX_CONNECT_PLUS',
  DHL_EXPRESS: 'DHL_EXPRESS',
  ECMS: 'ECMS',
  CHARTER: 'CHARTER', // 海外発送のみ
  SLS: 'SLS'
};

const SHIPPING_METHOD_WITH_DUTY_PATTERN = [];
for (const item in SHIPPING_METHOD_WITH_DUTY) {
  SHIPPING_METHOD_WITH_DUTY_PATTERN.push(item);
}

const SHIPPING_METHOD_BY_JAPAN_POST_PATTERN = [
  'JPP_EMS',
  'JPP_AIR_PARCEL',
  'JPP_SAL_PARCEL',
  'JPP_SURFACE_PARCEL',
  'JPP_SAL_SPR',
  'JPP_AIR_SPR',
  'JPP_E_PACKET', // 国際eパケットは廃止
  'JPP_AIR_PRINTED_MATTER_REGISTERED'
];

const AUTO_SELECTED_SHIPPING_METHOD_LABEL = {
  CHEAPEST: 'コスト最優先_セカイサジェスト',
  FASTEST: 'スピード最優先_セカイサジェスト',
  BESTVALUE: 'おすすめ_セカイサジェスト',
  ECONOMY: 'おすすめ_コスト重視_セカイサジェスト',
  EXPRESS: 'おすすめ_スピード重視_セカイサジェスト'
};

const AUTO_SELECTED_SHIPPING_METHOD = {
  CHEAPEST: 'CHEAPEST',
  FASTEST: 'FASTEST',
  BESTVALUE: 'BESTVALUE',
  ECONOMY: 'ECONOMY',
  EXPRESS: 'EXPRESS'
};

const AUTO_SELECTED_SHIPPING_METHOD_LABEL_PATTERN = [
  AUTO_SELECTED_SHIPPING_METHOD_LABEL.CHEAPEST,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL.FASTEST,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL.BESTVALUE,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL.ECONOMY,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL.EXPRESS
];

const AUTO_SELECTED_SHIPPING_METHOD_PATTERN = [
  AUTO_SELECTED_SHIPPING_METHOD.CHEAPEST,
  AUTO_SELECTED_SHIPPING_METHOD.FASTEST,
  AUTO_SELECTED_SHIPPING_METHOD.BESTVALUE,
  AUTO_SELECTED_SHIPPING_METHOD.ECONOMY,
  AUTO_SELECTED_SHIPPING_METHOD.EXPRESS
];

const ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_LABEL = {
  ECONOMY: 'おすすめ_コスト重視_セカイサジェスト',
  EXPRESS: 'おすすめ_スピード重視_セカイサジェスト',
  CHEAPEST: 'コスト最優先_セカイサジェスト',
  FASTEST: 'スピード最優先_セカイサジェスト'
};

const ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN = [];
for (const item in ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_LABEL) {
  ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN.push(item);
}

const ACCEPTABLE_SHIPPING_METHOD_LABEL = {
  ...SHIPPING_METHOD_LABEL,
  ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_LABEL
};

const ACCEPTABLE_SHIPPING_METHOD_LABEL_PATTERN = [];
for (const item in ACCEPTABLE_SHIPPING_METHOD_LABEL) {
  ACCEPTABLE_SHIPPING_METHOD_LABEL_PATTERN.push(
    ACCEPTABLE_SHIPPING_METHOD_LABEL[item]
  );
}

const ACCEPTABLE_SHIPPING_METHOD_PATTERN = [];
for (const item in ACCEPTABLE_SHIPPING_METHOD_LABEL) {
  ACCEPTABLE_SHIPPING_METHOD_PATTERN.push(item);
}

const DUTY = {
  CUSTOMER_PAID: 'CUSTOMER_PAID', // DDU、duty_paid: false
  OWNER_PAID: 'OWNER_PAID' // DDP、duty_paid: true
};
const DUTY_PATTERN = [];
for (const duty in DUTY) {
  DUTY_PATTERN.push(duty);
}

const DUTY_LABEL = {
  CUSTOMER_PAID: '関税着払い', // DDU、duty_paid: false
  OWNER_PAID: '関税元払い' // DDP、duty_paid: true
};
const DUTY_LABEL_PATTERN = [];
for (const item in DUTY_LABEL) {
  DUTY_LABEL_PATTERN.push(DUTY_LABEL[item]);
}

const SHIPPING_METHOD_LABEL_PATTERN = [];
for (const item in SHIPPING_METHOD_LABEL) {
  SHIPPING_METHOD_LABEL_PATTERN.push(SHIPPING_METHOD_LABEL[item]);
}

const SHIPPING_METHOD_PATTERN = [];
for (const item in SHIPPING_METHOD_LABEL) {
  SHIPPING_METHOD_PATTERN.push(item);
}

const SHIPPING_METHOD = {};
for (const key in SHIPPING_METHOD_LABEL) {
  SHIPPING_METHOD[key] = key;
}

// 国内向けの配送方法一覧
const DOMESTIC_SHIPPING_METHOD_PATTERN = [
  SHIPPING_METHOD.SAGAWA,
  SHIPPING_METHOD.YAMATO
];

// 自動選択種類ごとに優先度順スコアを保持する
// 数字が大きい方が優先的に選択される
// 数字が同じ場合は同じもの同士で送料が安いほうが選ばれる
// コスト最優先は全て同じ優先度にすることで送料による並び替えにさせている
const AUTO_SELECTED_SHIPPING_METHOD_OPTION = {
  // 国内は全部同じ
  DOMESTIC: {
    FASTEST: {
      [SHIPPING_METHOD.SAGAWA]: 10,
      [SHIPPING_METHOD.YAMATO]: 10,
      [SHIPPING_METHOD.YAMATO_NEKOPOS]: 10
    },
    CHEAPEST: {
      [SHIPPING_METHOD.SAGAWA]: 10,
      [SHIPPING_METHOD.YAMATO]: 10,
      [SHIPPING_METHOD.YAMATO_NEKOPOS]: 10
    },
    ECONOMY: {
      [SHIPPING_METHOD.SAGAWA]: 10,
      [SHIPPING_METHOD.YAMATO]: 10,
      [SHIPPING_METHOD.YAMATO_NEKOPOS]: 10
    },
    EXPRESS: {
      [SHIPPING_METHOD.SAGAWA]: 10,
      [SHIPPING_METHOD.YAMATO]: 10,
      [SHIPPING_METHOD.YAMATO_NEKOPOS]: 10
    }
  },
  // JPP_AIR_PRINTED_MATTER_REGISTEREDは印刷物以外にサジェストされる可能性があるため外す
  OVERSEA: {
    // 早い手段の優先度を上げたもの
    FASTEST: {
      [SHIPPING_METHOD.DHL_EXPRESS]: 50,
      [SHIPPING_METHOD.FEDEX_CONNECT_PLUS]: 40,
      [SHIPPING_METHOD.JPP_EMS]: 40,
      [SHIPPING_METHOD.ECMS]: 30,
      [SHIPPING_METHOD.JPP_AIR_PARCEL]: 10,
      [SHIPPING_METHOD.JPP_SURFACE_PARCEL]: 10,
      [SHIPPING_METHOD.JPP_AIR_SPR]: 20
      // [SHIPPING_METHOD.JPP_AIR_PRINTED_MATTER_REGISTERED]: 20
    },
    // 全て優先度を同じにして，送料でのソートを適用させる
    CHEAPEST: {
      [SHIPPING_METHOD.DHL_EXPRESS]: 10,
      [SHIPPING_METHOD.FEDEX_CONNECT_PLUS]: 10,
      [SHIPPING_METHOD.ECMS]: 10,
      [SHIPPING_METHOD.JPP_EMS]: 10,
      [SHIPPING_METHOD.JPP_AIR_PARCEL]: 10,
      [SHIPPING_METHOD.JPP_SURFACE_PARCEL]: 10,
      [SHIPPING_METHOD.JPP_AIR_SPR]: 10
      // [SHIPPING_METHOD.JPP_AIR_PRINTED_MATTER_REGISTERED]: 10
    },
    ECONOMY: {
      [SHIPPING_METHOD.DHL_EXPRESS]: 20,
      [SHIPPING_METHOD.FEDEX_CONNECT_PLUS]: 20,
      [SHIPPING_METHOD.ECMS]: 20,
      [SHIPPING_METHOD.JPP_EMS]: 20,
      [SHIPPING_METHOD.JPP_AIR_PARCEL]: 20,
      [SHIPPING_METHOD.JPP_SURFACE_PARCEL]: 10,
      [SHIPPING_METHOD.JPP_AIR_SPR]: 20
    },
    EXPRESS: {
      [SHIPPING_METHOD.DHL_EXPRESS]: 40,
      [SHIPPING_METHOD.FEDEX_CONNECT_PLUS]: 40,
      [SHIPPING_METHOD.JPP_EMS]: 40,
      [SHIPPING_METHOD.ECMS]: 30,
      [SHIPPING_METHOD.JPP_AIR_PARCEL]: 30,
      [SHIPPING_METHOD.JPP_SURFACE_PARCEL]: 10,
      [SHIPPING_METHOD.JPP_AIR_SPR]: 20
    }
  }
};

// 梱包時にどの重量で分割するかの最大重量
const SINGLE_PACKAGE_MAX_WEIGHT = {
  JPP_EMS: 30000,
  JPP_AIR_PARCEL: 30000,
  JPP_SAL_PARCEL: 30000,
  JPP_SURFACE_PARCEL: 30000,
  JPP_AIR_SPR: 2000,
  JPP_SAL_SPR: 2000,
  JPP_E_PACKET: 2000, // 国際eパケットは廃止
  JPP_AIR_PRINTED_MATTER_REGISTERED: 5000,
  YAMATO: 30000,
  YAMATO_NEKOPOS: 1000,
  // 佐川・FedEx・DHLは30kgで分割するために上限を30kgと定める
  // 伝票直張りの分割が関係しないものはタリフの上限まで送れる
  SAGAWA: 30000,
  FEDEX_PRIORITY: 30000,
  FEDEX_ECONOMY: 30000,
  FEDEX_CONNECT_PLUS: 30000,
  DHL_EXPRESS: 30000,
  ECMS: {
    US: 30000,
    CN: 30000,
    HK: 30000,
    TW: 30000,
    KR: 30000,
    SG: 30000,
    TH: 30000,
    MY: 30000,
    ID: 30000,
    VN: 30000,
    PH: 30000,
    AU: 30000,
    CA: 28000
  },
  SLS: {
    SG: 30000,
    PH: 30000,
    MY: 30000,
    TW: 10000,
    VN: 15000
  }
};

// 配送方法毎の最大重量
// SINGLE_PACKAGE_MAX_WEIGHT は梱包時の最大重量でそれを超えないように分割される
// こちらは伝票直貼りで送れる最大重量
const SHIPPING_MAX_WEIGHT = {
  ...SINGLE_PACKAGE_MAX_WEIGHT,
  SAGAWA: 50000,
  FEDEX_PRIORITY: 68000,
  FEDEX_ECONOMY: 68000,
  // 特別取り扱い料金を超えない基準で設定した
  FEDEX_CONNECT_PLUS: 30000,
  DHL_EXPRESS: 70000
};

const YAMATO_NEKOPOS_SHIPPING_COST = 300;

const RECOMMENDED_PACKING_BOX_SIZE_NO_BOX = '梱包なし_伝票直貼り';

// TOM の電話番号を使用すると直接電話がかかってきてしまうのでダミーの電話番号を使用する
const DUMMY_PHONE_NUMBER = '00000000000';
// 海外向けの配送でダミーの電話番号だと税関からの連絡などで不都合が生じるためTOMの代表電話番号を使用する
const CORPORATE_CONTACT_NUMBER = '+81-50-3803-2910';

const SHIPPING_PAPER_CATEGORY = {
  SLIP: 'slip',
  LABEL: 'label',
  INVOICE: 'invoice'
};

const SHIPPING_PAPER_CATEGORY_PATTERN = [];
for (const item in SHIPPING_PAPER_CATEGORY) {
  SHIPPING_PAPER_CATEGORY_PATTERN.push(SHIPPING_PAPER_CATEGORY[item]);
}

const DEFAULT_PRIORITY = 100;

const CONTENT_TYPE_LABEL = {
  GIFT: '贈り物',
  SAMPLE: '商品見本',
  MERCHANDISE: '販売品',
  RETURN: '返送品',
  REPAIR: '修理品',
  OTHERS: 'その他'
};

const CONTENT_TYPE = {}; // GIFT: GIFT のようなオブジェクト
for (const key of Object.keys(CONTENT_TYPE_LABEL)) {
  CONTENT_TYPE[key] = key;
}

module.exports = {
  STATE,
  STATE_PATTERN,
  STATE_LABEL,
  SHIPPING_METHOD_LABEL,
  SHIPPING_METHOD_LABEL_PATTERN,
  SHIPPING_METHOD,
  SHIPPING_METHOD_PATTERN,
  DOMESTIC_SHIPPING_METHOD_PATTERN,
  AUTO_SELECTED_SHIPPING_METHOD,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL,
  AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL_PATTERN,
  AUTO_SELECTED_SHIPPING_METHOD_OPTION,
  SHIPPING_MAX_WEIGHT,
  SINGLE_PACKAGE_MAX_WEIGHT,
  SHIPPING_METHOD_WITH_DUTY,
  SHIPPING_METHOD_WITH_DUTY_PATTERN,
  SHIPPING_METHOD_BY_JAPAN_POST_PATTERN,
  ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_LABEL,
  ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
  ACCEPTABLE_SHIPPING_METHOD_LABEL,
  ACCEPTABLE_SHIPPING_METHOD_LABEL_PATTERN,
  ACCEPTABLE_SHIPPING_METHOD_PATTERN,
  DUTY,
  DUTY_PATTERN,
  DUTY_LABEL,
  DUTY_LABEL_PATTERN,
  YAMATO_NEKOPOS_SHIPPING_COST,
  RECOMMENDED_PACKING_BOX_SIZE_NO_BOX,
  DUMMY_PHONE_NUMBER,
  CORPORATE_CONTACT_NUMBER,
  SHIPPING_PAPER_CATEGORY,
  SHIPPING_PAPER_CATEGORY_PATTERN,
  DEFAULT_PRIORITY,
  CONTENT_TYPE,
  CONTENT_TYPE_LABEL
};
